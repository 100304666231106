body {
  margin: 0;
  font-family: -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
  /*background*/
  /*background: rgb(255,255,255);*/
  /*background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(180,136,17,0.28755252100840334) 100%, rgba(0,0,0,0) 100%);*/
  /*background-attachment: fixed;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
