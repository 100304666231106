//Text Styles
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");
$font-sans: 'Open Sans', sans-serif;

//Colors
$black: #34435E;
$white: #ffffff;

//body {
//    font-family: $font-sans;
//    margin: 0;
//    padding: 0 4em;
//}

article#timeline {
    //min-width: 300px;
    //max-width: 500px;
    margin: auto;
}

#timeline p {
    font-size: 1em;
    line-height: 1.75em;
    border-top: 3px solid;
    border-image: -webkit-gradient(linear, left top, left bottom, from(#AE8913), to(#FFEEB5));
    border-image-slice: 1;
    border-width: 3px;
    margin: 0;
    padding: 40px;
    counter-increment: section;
    position: relative;
    color: $black;

    //numbers
    &:before {
        content: counter(section);
        position: absolute;
        border-radius: 50%;
        /* padding: 10px; */
        height: 1.25em;
        width: 1.25em;
        background-color: #34435E;
        text-align: center;
        line-height: 1.15em;
        color: #ffffff;
        font-size: 1em;
    }
}
//odd number borders
#timeline p:nth-child(odd) {
    border-right: 3px solid;
    padding-left: 0;

    &:before {
        left: 100%;
        margin-left: -25px;
        margin-top: 3px;
    }
}
//even number borders
#timeline p:nth-child(even) {
    border-left: 3px solid;
    padding-right: 0;

    &:before {
        right: 100%;
        margin-right: -25px;
        margin-top: 3px;
    }
}
//handle first and last
#timeline p:first-child {
    border-top: 0;
    border-top-right-radius:0;
    border-top-left-radius:0;
}
#timeline p:last-child {
    border-bottom-right-radius:0;
    border-bottom-left-radius:0;
}
