@media only screen and (min-width: 720px) {

}

.register-page-wrapper *{
    text-align: right;
}
.register-background{
    background-image: url("../assets/buildings.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 350px;
    width: 100%;
}


