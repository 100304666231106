.App {
  text-align: center;
  width: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.display-none{
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 720px)  {
  .navbar-light.bg-light .nav-item a{
    border-bottom: 1px solid var(--gold-color);
    border-radius: inherit;
  }
}
#root{
    /*height: 100vh;*/
}
#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#root>div.editDetailsPage,
#root>div.rentersPage,
#root>div.loginPage,
#root>div.paymentPage,
#root>div.registerPage,
#root>div.contactUsPage,
#root>div.aboutUsPage
{
  width: -webkit-fill-available;
  margin: 15px;
  border-radius: 5px;
    overflow: auto;
}
::-webkit-scrollbar {
    display: none;
}
@media only screen and (min-width: 720px)  {
  #root>div.editDetailsPage,
  #root>div.rentersPage,
  #root>div.loginPage,
  #root>div.paymentPage,
  #root>div.registerPage,
  #root>div.contactUsPage,
  #root>div.aboutUsPage
  {
    max-width: var(--max-desktop-size);
  }
  .navbar-light .navbar-nav{
    padding: 0 10px;
  }
}

.navbar{
  box-shadow: 0px 3px 10px #FFEEB5;
  width: inherit;
  top: -1px;
  /*background-color: #FFEEB5;*/
    /*background: #FFEEB5 -webkit-gradient(linear, left top, left bottom, from(#AE8913), to(#FFEEB5)) no-repeat;*/
    /*background: #FFEEB5 -moz-linear-gradient(top, #AE8913, #FFEEB5) no-repeat;*/
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#AE8913, endColorstr=#FFEEB5) no-repeat;*/
    /*-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#AE8913, endColorstr=#AE8913)" no-repeat;*/
}

.navbar-light .navbar-nav a.nav-link{
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link{
  color: var(--gold-color) !important;
  background-color: transparent;
  border-bottom: 1px solid var(--gold-color);
  border-radius: inherit;
}
.navbar-light .navbar-toggler{
  color: white;
  border-color: white;
}
.navbar-light.bg-light {
  background-color: var(--primary-color-light) !important;
}
.navbar-light.bg-light .nav-item a, .navbar-brand{
  color: white !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body{
  /*background: rgb(2,0,36);*/
  /*background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(16,101,224,0.7035189075630253) 43%, rgba(0,212,255,1) 100%);*/

  background: rgb(13,9,105);
  /*background: linear-gradient(90deg, rgba(13,9,105,1) 54%, rgba(33,150,243,1) 100%);*/
  background: linear-gradient(90deg, rgba(13,9,105,1) 0%, rgba(33,150,243,1) 50%);


}
#root{
  width: 100%;
    height: calc(100vh - 5.5rem);
}
#root>div{
  margin: 0 auto;
  margin-top: 10px;
  /*border: 1px solid;*/
  /*padding: 10px;*/
  box-shadow: 0px 0px 15px;
  background: white;
}
#root>div{
    margin-top: 100px !important;
}
.app-footer{
  /*position: absolute;*/
  bottom: 0;
  width: 100%;
  height: 50px;
}


/*@media (min-width: 768px) {
  .custom_fab-container.fab-container {
    right: 15vw;
  }
}*/

.custom_fab-container.fab-container {
  bottom: 0vh !important;
  position: fixed !important;
  margin: 1em !important;
  right: 0vw;
  z-index: 2;
}

.fab-container button{
  background-color: var(--primary-color) !important;
}
.fab-container a.fab-item{
  background-color: var(--primary-color) !important;;
}
.fab-container button:before{
  display: none;
}


.modal-header, .modal-footer{
  padding: 0.5rem 1rem;
}
.modal .logo{
  margin-left: 10px;
}
.navbar .logo{
  margin-left: 10px;
}
.logo{
  width: 40px;
}
.modal-header .close{
  position: absolute;
  top: 15px;
}

.IPAY .modal-body{
  padding-top: 0;
}

.force-select {
  -webkit-user-select: all;  /* Chrome 49+ */
  -moz-user-select: all;     /* Firefox 43+ */
  -ms-user-select: all;      /* No support yet */
  user-select: all;          /* Likely future */
}

.pulseContainer{
  /*transform: scale(1);*/
  animation: pulse 2s;
  animation-iteration-count: 3;
}
@keyframes pulse {
  0% {

  }

  50% {
    /*text-shadow: 1px 1px 5px var(--gold-color);*/
    color: var(--gold-color);
  }

  100% {

  }
}
.rentersPage,
.registerPage,
.editDetailsPage,
.contactUsPage,
.paymentPage,
.aboutUsPage,
.loginPage {
  height: inherit;
}

.security {
  bottom: 0;
}

.privacy-modal * {
  color: var(--primary-color);
}
