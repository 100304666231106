.renterName{

}
.renterName:hover, .renterName:visited{

}

.classA:hover, .classA:hover .classB, .classA:hover .classB {
    background: var(--primary-color);
    color: white;
}

.statusIconSize svg {
    height: auto;
    width: 30px !important;
    color: var(--primary-color) !important;
}
.card.border-gold {
    /*box-shadow: 0px 0px 5px var(--gold-color);*/
    /*border: 1px solid var(--gold-color) !important;*/
    /*border: 1px solid #00fff5 !important;*/
    /*box-shadow: 0px 0px 10px #00fff5*/
    box-shadow: 0px 0px 10px var(--primary-color)
}


.rentersPage{
    padding-bottom: 50px;
    background: transparent !important;
    box-shadow: none !important;
}


.floating-menu {
    position: fixed;
    right: 15px;
    bottom: 25px;
    height: 42px;
    text-align: right;
    /*transition: height 250ms ease-in-out;*/
}

.floating-menu.open {
    height: 150px;
    overflow: hidden;
}
.floating-menu.open > .mainMenuIcon svg{
    transform: rotate( -45deg );
    transition: transform 150ms ease;
}
.floating-menu.closed > .mainMenuIcon svg{
    transform: rotate( 0deg );
    transition: transform 150ms ease;
}
.floating-menu.open .floating-menu-item {
    margin-top: 8px;
}

.floating-menu-item label{
    display: inline-block;
    margin-right: 8px;
    padding: 4px 10px;
    border-radius: 12px;
    background: rgba(0,0,0,.25);
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}

.floating-menu.open .floating-menu-item label {
    opacity: 0.6;
}

.floating-menu-item.mainMenuIcon > .floating-menu-icon{
    width: 56px;
    height: 56px;
    line-height: 56px;
}
.floating-menu-icon {
    display: inline-block;
    width: 42px;
    height: 42px;
    line-height: 42px;
    vertical-align: middle;
    background: var(--primary-color);
    border-radius: 50%;
    border: none;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    text-align: center;
}

.floating-menu-icon .material-icons {
    font-size: 32px;
    color: #fff;
    vertical-align: middle;
}
