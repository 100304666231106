.loginPage .loginTitle {

}
.loginPage {
    background-image: url('../assets/check.jpg') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}

.gotoRegister {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: var(--primary-color);
    position: absolute;
    bottom: 30px;
    margin-right: 15px;
    color: white;
    box-shadow: 0px 0px 10px #00fff5
}
.gotoRegister:hover {
    background: white;
    color: var(--primary-color);
}
.smallHeight .gotoRegister{
    display: none;
}
