@media only screen and (min-width: 720px) {

}

.contactUsPage * {
    /*text-align: right;*/
}
.contactus-background{
    background-image: url("../assets/contactus_background.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 150px;
}
