@media only screen and (max-width: 600px) {
    .aboutus-background{
        background-size: contain !important;
    }
}

.aboutUsPage *{
    text-align: right;
}
.aboutus-background{
    background-image: url("../assets/infoPage.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 233px;
    width: 100%;
}
