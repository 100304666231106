.add-renter .modal-dialog{
    max-width: 500px;
}

.add-renter .form{
    padding: 10px 50px;
}

@media only screen and (max-width: 720px)  {
    .add-renter .form{
        padding: 10px 10px;
    }
}
