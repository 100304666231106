@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700);
body {
  margin: 0;
  font-family: -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
  /*background*/
  /*background: rgb(255,255,255);*/
  /*background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(180,136,17,0.28755252100840334) 100%, rgba(0,0,0,0) 100%);*/
  /*background-attachment: fixed;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper{
    padding:10px;
}
.form{

}
.form .form-check-inline{
    margin-right: 0;
}
.form .form-check-inline input{
    margin-left: 5px;
}
.form input[type="checkbox"]:checked:after,
.form input[type="radio"]:checked:after{
    right: 10px;
}
.form .form-group{
    text-align: right;
    width: 100%;
    margin-bottom: 0.5rem;
}
input[type=date]:required:invalid::-webkit-datetime-edit {
    color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
}
/*input[type=date]::-webkit-calendar-picker-indicator */
input[type=date]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.invalidInput{
    color: #ca7878;
}
.invalidInputBorder{
    border-color : red;
}

.privacyButton{
    padding: 0 5px;
    background: transparent;
    color: black;
    border: none;
}

.color-a{
    color: #547aa6 !important;
    text-decoration: underline !important;
}

.form li {
    padding-right: 15px;
}



/*date input*/
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

input {
    position: relative;
    width: 150px; height: 20px;
    color: white;
}
input, select, textarea {
    box-shadow: 0 0 5px !important;
    border-color: var(--primary-color) !important;
}
input:before {
    position: absolute;
    /*top: 3px; left: 3px;*/
    content: attr(data-date);
    display: inline-block;
    color: black;
}

input::-webkit-datetime-edit,
input::-webkit-inner-spin-button,
input::-webkit-date-and-time-value,
input::-webkit-clear-button {
    display: none;
}

input::-webkit-calendar-picker-indicator {
    position: absolute;
    /*top: 3px;*/
    right: 0;
    color: black;
    opacity: 1;
}

@media only screen and (min-width: 720px) {

}

.register-page-wrapper *{
    text-align: right;
}
.register-background{
    background-image: url(../../static/media/buildings.080a7c30.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 350px;
    width: 100%;
}



article#timeline {
  margin: auto; }

#timeline p {
  font-size: 1em;
  line-height: 1.75em;
  border-top: 3px solid;
  border-image: -webkit-gradient(linear, left top, left bottom, from(#AE8913), to(#FFEEB5));
  border-image-slice: 1;
  border-width: 3px;
  margin: 0;
  padding: 40px;
  counter-increment: section;
  position: relative;
  color: #34435E; }
  #timeline p:before {
    content: counter(section);
    position: absolute;
    border-radius: 50%;
    /* padding: 10px; */
    height: 1.25em;
    width: 1.25em;
    background-color: #34435E;
    text-align: center;
    line-height: 1.15em;
    color: #ffffff;
    font-size: 1em; }

#timeline p:nth-child(odd) {
  border-right: 3px solid;
  padding-left: 0; }
  #timeline p:nth-child(odd):before {
    left: 100%;
    margin-left: -25px;
    margin-top: 3px; }

#timeline p:nth-child(even) {
  border-left: 3px solid;
  padding-right: 0; }
  #timeline p:nth-child(even):before {
    right: 100%;
    margin-right: -25px;
    margin-top: 3px; }

#timeline p:first-child {
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

#timeline p:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.loginPage .loginTitle {

}
.loginPage {
    background-image: url(../../static/media/check.190d9613.jpg) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}

.gotoRegister {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: var(--primary-color);
    position: absolute;
    bottom: 30px;
    margin-right: 15px;
    color: white;
    box-shadow: 0px 0px 10px #00fff5
}
.gotoRegister:hover {
    background: white;
    color: var(--primary-color);
}
.smallHeight .gotoRegister{
    display: none;
}

@media only screen and (max-width: 600px) {
    .aboutus-background{
        background-size: contain !important;
    }
}

.aboutUsPage *{
    text-align: right;
}
.aboutus-background{
    background-image: url(../../static/media/infoPage.cdfed549.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 233px;
    width: 100%;
}

@media only screen and (min-width: 720px) {

}

.contactUsPage * {
    /*text-align: right;*/
}
.contactus-background{
    background-image: url(../../static/media/contactus_background.b6070847.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 150px;
}

.renterName{

}
.renterName:hover, .renterName:visited{

}

.classA:hover, .classA:hover .classB, .classA:hover .classB {
    background: var(--primary-color);
    color: white;
}

.statusIconSize svg {
    height: auto;
    width: 30px !important;
    color: var(--primary-color) !important;
}
.card.border-gold {
    /*box-shadow: 0px 0px 5px var(--gold-color);*/
    /*border: 1px solid var(--gold-color) !important;*/
    /*border: 1px solid #00fff5 !important;*/
    /*box-shadow: 0px 0px 10px #00fff5*/
    box-shadow: 0px 0px 10px var(--primary-color)
}


.rentersPage{
    padding-bottom: 50px;
    background: transparent !important;
    box-shadow: none !important;
}


.floating-menu {
    position: fixed;
    right: 15px;
    bottom: 25px;
    height: 42px;
    text-align: right;
    /*transition: height 250ms ease-in-out;*/
}

.floating-menu.open {
    height: 150px;
    overflow: hidden;
}
.floating-menu.open > .mainMenuIcon svg{
    transform: rotate( -45deg );
    transition: transform 150ms ease;
}
.floating-menu.closed > .mainMenuIcon svg{
    transform: rotate( 0deg );
    transition: transform 150ms ease;
}
.floating-menu.open .floating-menu-item {
    margin-top: 8px;
}

.floating-menu-item label{
    display: inline-block;
    margin-right: 8px;
    padding: 4px 10px;
    border-radius: 12px;
    background: rgba(0,0,0,.25);
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}

.floating-menu.open .floating-menu-item label {
    opacity: 0.6;
}

.floating-menu-item.mainMenuIcon > .floating-menu-icon{
    width: 56px;
    height: 56px;
    line-height: 56px;
}
.floating-menu-icon {
    display: inline-block;
    width: 42px;
    height: 42px;
    line-height: 42px;
    vertical-align: middle;
    background: var(--primary-color);
    border-radius: 50%;
    border: none;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    text-align: center;
}

.floating-menu-icon .material-icons {
    font-size: 32px;
    color: #fff;
    vertical-align: middle;
}

.add-renter .modal-dialog{
    max-width: 500px;
}

.add-renter .form{
    padding: 10px 50px;
}

@media only screen and (max-width: 720px)  {
    .add-renter .form{
        padding: 10px 10px;
    }
}

.App {
  text-align: center;
  width: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.display-none{
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 720px)  {
  .navbar-light.bg-light .nav-item a{
    border-bottom: 1px solid var(--gold-color);
    border-radius: inherit;
  }
}
#root{
    /*height: 100vh;*/
}
#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#root>div.editDetailsPage,
#root>div.rentersPage,
#root>div.loginPage,
#root>div.paymentPage,
#root>div.registerPage,
#root>div.contactUsPage,
#root>div.aboutUsPage
{
  width: -webkit-fill-available;
  margin: 15px;
  border-radius: 5px;
    overflow: auto;
}
::-webkit-scrollbar {
    display: none;
}
@media only screen and (min-width: 720px)  {
  #root>div.editDetailsPage,
  #root>div.rentersPage,
  #root>div.loginPage,
  #root>div.paymentPage,
  #root>div.registerPage,
  #root>div.contactUsPage,
  #root>div.aboutUsPage
  {
    max-width: var(--max-desktop-size);
  }
  .navbar-light .navbar-nav{
    padding: 0 10px;
  }
}

.navbar{
  box-shadow: 0px 3px 10px #FFEEB5;
  width: inherit;
  top: -1px;
  /*background-color: #FFEEB5;*/
    /*background: #FFEEB5 -webkit-gradient(linear, left top, left bottom, from(#AE8913), to(#FFEEB5)) no-repeat;*/
    /*background: #FFEEB5 -moz-linear-gradient(top, #AE8913, #FFEEB5) no-repeat;*/
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#AE8913, endColorstr=#FFEEB5) no-repeat;*/
    /*-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#AE8913, endColorstr=#AE8913)" no-repeat;*/
}

.navbar-light .navbar-nav a.nav-link{
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link{
  color: var(--gold-color) !important;
  background-color: transparent;
  border-bottom: 1px solid var(--gold-color);
  border-radius: inherit;
}
.navbar-light .navbar-toggler{
  color: white;
  border-color: white;
}
.navbar-light.bg-light {
  background-color: var(--primary-color-light) !important;
}
.navbar-light.bg-light .nav-item a, .navbar-brand{
  color: white !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body{
  /*background: rgb(2,0,36);*/
  /*background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(16,101,224,0.7035189075630253) 43%, rgba(0,212,255,1) 100%);*/

  background: rgb(13,9,105);
  /*background: linear-gradient(90deg, rgba(13,9,105,1) 54%, rgba(33,150,243,1) 100%);*/
  background: linear-gradient(90deg, rgba(13,9,105,1) 0%, rgba(33,150,243,1) 50%);


}
#root{
  width: 100%;
    height: calc(100vh - 5.5rem);
}
#root>div{
  margin: 0 auto;
  margin-top: 10px;
  /*border: 1px solid;*/
  /*padding: 10px;*/
  box-shadow: 0px 0px 15px;
  background: white;
}
#root>div{
    margin-top: 100px !important;
}
.app-footer{
  /*position: absolute;*/
  bottom: 0;
  width: 100%;
  height: 50px;
}


/*@media (min-width: 768px) {
  .custom_fab-container.fab-container {
    right: 15vw;
  }
}*/

.custom_fab-container.fab-container {
  bottom: 0vh !important;
  position: fixed !important;
  margin: 1em !important;
  right: 0vw;
  z-index: 2;
}

.fab-container button{
  background-color: var(--primary-color) !important;
}
.fab-container a.fab-item{
  background-color: var(--primary-color) !important;;
}
.fab-container button:before{
  display: none;
}


.modal-header, .modal-footer{
  padding: 0.5rem 1rem;
}
.modal .logo{
  margin-left: 10px;
}
.navbar .logo{
  margin-left: 10px;
}
.logo{
  width: 40px;
}
.modal-header .close{
  position: absolute;
  top: 15px;
}

.IPAY .modal-body{
  padding-top: 0;
}

.force-select {
  -webkit-user-select: all;  /* Chrome 49+ */     /* Firefox 43+ */
  -ms-user-select: all;      /* No support yet */
  user-select: all;          /* Likely future */
}

.pulseContainer{
  /*transform: scale(1);*/
  animation: pulse 2s;
  animation-iteration-count: 3;
}
@keyframes pulse {
  0% {

  }

  50% {
    /*text-shadow: 1px 1px 5px var(--gold-color);*/
    color: var(--gold-color);
  }

  100% {

  }
}
.rentersPage,
.registerPage,
.editDetailsPage,
.contactUsPage,
.paymentPage,
.aboutUsPage,
.loginPage {
  height: inherit;
}

.security {
  bottom: 0;
}

.privacy-modal * {
  color: var(--primary-color);
}

